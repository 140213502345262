import React, { Component, Fragment } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import AppURL from '../../../api/AppURL';
import axios from 'axios';
import HomeFeaturesLoading from '../../PlaceHolder/HomeFeaturesLoading';

class DesktopHomeFeatures extends Component {

    constructor() {
        super();
        this.state = {
            HomeFeaturesData: [],
            isLoading: "",
            mainDiv: "d-none",
            seo_title: "",
            seo_description: "",
            seo_keywords: "",
            page_name: ""
        }
    }
    componentDidMount() {
        axios.post(AppURL.GetHomeFeatures).then(response => {

            this.setState({ HomeFeaturesData: response.data['Home Features'], isLoading: "d-none", mainDiv: "" });

        }).catch(error => {

        });

        axios.post(AppURL.GetStaticSeo("Home")).then(response => {
            let StatusCode = response.status;
            if (StatusCode == 200) {
                let JsonData = (response.data['Seo'])[0];
                this.setState({
                    seo_title: JsonData['seo_title'],

                });
            }

        }).catch(error => {

        });


    }

    render() {

        const HomeFeaturesList = this.state.HomeFeaturesData;
        const HomeFeaturesView = HomeFeaturesList.map((HomeFeaturesList, i) => {
            return <Col lg={4} md={4} xl={4} xxl={4} sm={4} xs={4} className='text-center' key={i.toString()}>
                <div className='HomeFeatures-box-1'>
                    <span className="HomeFeatures-inner-box">
                        <img src={HomeFeaturesList.features_image} alt={this.state.seo_title} className="img-fluid rounded" />
                    </span>
                    <div>
                        <h5>{HomeFeaturesList.features_name}</h5>
                        <div dangerouslySetInnerHTML={{ __html: HomeFeaturesList.features_text }} />
                    </div>
                    <a href={HomeFeaturesList.page_name} className="btn_one">{HomeFeaturesList.button_name}</a>

                </div>
            </Col>




        })

        return (
            <Fragment>
                <Container>
                    <Row className="align-items-center">
                        <HomeFeaturesLoading isLoading={this.state.isLoading} />
                        <Row className={this.state.mainDiv}>


                            {HomeFeaturesView}
                        </Row>
                    </Row>
                </Container>
            </Fragment>
        )
    }
}

export default DesktopHomeFeatures
