import React, { Component } from 'react'
import { Fragment } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import AppURL from '../../api/AppURL';
import axios from 'axios';

class PageFeatures extends Component {
    constructor() {
        super();
        this.state = {
            PageData: [],
            seo_title: "",
            seo_description: "",
            seo_keywords: ""
        }
    }

    componentDidMount() {

        let page_url = this.props.page_url;
        axios.post(AppURL.GetPageFeatures(page_url)).then(response => {

            this.setState({ PageData: response.data['Page Features'] });

        }).catch(error => {

        });


        axios.post(AppURL.GetSeo(page_url)).then(response => {
            let StatusCode = response.status;
            if (StatusCode == 200) {
                let JsonData = (response.data['Seo'])[0];
                this.setState({
                    seo_title: JsonData['seo_title'],

                });
            }

        }).catch(error => {

        });



    }
    render() {
        let PageFeaturesList = this.state.PageData;

        if (PageFeaturesList.length > 0) {
            const PageFeaturesView = PageFeaturesList.map((PageFeaturesList, i) => {

                return <Col lg={4} md={4} xl={4} xxl={4} sm={4} xs={4} className='text-center' key={i.toString()}>

                    <img src={PageFeaturesList.features_image} alt={this.state.seo_title} className="img-fluid rounded" />
                    <h4>{PageFeaturesList.features_name}</h4>
                    <div dangerouslySetInnerHTML={{ __html: PageFeaturesList.features_text }} />
                </Col>
            })


            return (
                <Fragment>

                    <Row>
                        {PageFeaturesView}

                    </Row>

                </Fragment>
            )
        }
        else {

        };
    }
}

export default PageFeatures
