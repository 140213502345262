import React, { Component } from 'react'
import { Breadcrumb, Container, Row, Col } from 'react-bootstrap'
import { Fragment } from 'react'
import { Helmet } from "react-helmet";
import ApplyNow from '../components/forms/ApplyNow';
class Apply extends Component {
    render() {
        return (
            <Fragment>
                <Helmet>
                    <title>Online application form - Rostov State Medical University</title>
                    <meta name="description" content="Rostov State Medical University is a prominent Institution in the field of Medicine in the region of Southern Russia. Duration 6 Years. Book your seat for 2023." />
                    <link rel="canonical" href="https://rostgmu.net/apply-online-for-study-medicine-in-rostov-state-medical-university-russia/" />
                </Helmet>


                <section className='inner-pages-details-apply'>
                    <Container>
                        <Row>
                            <Col lg={12} md={12} xl={12} xxl={12} sm={12} xs={12}>
                                <div className='bg-danger ptb-100 mt-30'>
                                    <ApplyNow />
                                </div>
                            </Col>
                        </Row>

                    </Container>

                </section>
            </Fragment>
        )
    }
}

export default Apply
