import React, { Component, Fragment } from 'react'
import { Container, Row, Col, Accordion } from 'react-bootstrap'
import AppURL from '../../api/AppURL';
import axios from 'axios';
import FaqsLoading from '../../components/PlaceHolder/FaqsLoading';

class MobilePageFaqs extends Component {

    constructor() {
        super();
        this.state = {
            FaqsData: [],
            isLoading: "",
            mainDiv: "d-none"
        }
    }
    componentDidMount() {
        axios.post(AppURL.GetFaqs).then(response => {

            this.setState({ FaqsData: response.data['Faqs'], isLoading: "d-none", mainDiv: "" });

        }).catch(error => {

        });


    }

    render() {

        const FaqsList = this.state.FaqsData;
        const FaqsView = FaqsList.map((FaqsList, i) => {
            return <Col xl={12} lg={12} md={12} sm={12} xs={12} key={i.toString()}>
                <Accordion.Item eventKey={FaqsList.id}>
                    <Accordion.Header><h4>{FaqsList.faqs_name}</h4></Accordion.Header>
                    <Accordion.Body>
                        <div dangerouslySetInnerHTML={{ __html: FaqsList.faqs_text }} />

                    </Accordion.Body>
                </Accordion.Item>
            </Col>




        })

        return (
            <Fragment>
                <Container>
                    <Row className="align-items-center">
                        <FaqsLoading isLoading={this.state.isLoading} />
                        <Row className={this.state.mainDiv}>
                            <Accordion defaultActiveKey="0">
                                {FaqsView}
                            </Accordion>
                        </Row>
                    </Row>
                </Container>
            </Fragment>
        )
    }
}

export default MobilePageFaqs
