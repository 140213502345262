import React, { Component } from 'react'
import { Fragment } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import AppURL from '../../api/AppURL';
import axios from 'axios';

class PageContact extends Component {
    constructor() {
        super();
        this.state = {
            PageData: []
        }
    }

    componentDidMount() {

        let page_url = this.props.page_url;
        axios.post(AppURL.GetPageContact(page_url)).then(response => {

            this.setState({ PageData: response.data['Page Contact'] });

        }).catch(error => {

        });

    }
    render() {
        let PageContactList = this.state.PageData;

        if (PageContactList.length > 0) {
            const PageContactView = PageContactList.map((PageContactList, i) => {

                return <Col lg={12} md={12} xl={12} xxl={12} sm={12} xs={12} className='text-center' key={i.toString()}>
                    <h4>{PageContactList.contact_name}</h4>
                    <div dangerouslySetInnerHTML={{ __html: PageContactList.contact_text }} />
                </Col>
            })


            return (
                <Fragment>
                    <section className='inner-cpntact-details'>
                        <Container>
                            <Row>

                                {PageContactView}


                            </Row>
                        </Container>
                    </section>

                </Fragment>
            )
        }
        else {

        };
    }
}

export default PageContact
