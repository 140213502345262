import axios from 'axios';
import React, { Component, Fragment } from 'react';
import { createBrowserHistory } from 'history';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Link } from 'react-router-dom';

import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";

import AppURL from '../api/AppURL'

import Home from '../pages/Home';
import Gallery from '../pages/Gallery';
import Video from '../pages/Video';
import Clients from '../pages/Clients';
import Testimonials from '../pages/Testimonials';
import Blog from '../pages/Blog';
import LatestNews from '../pages/LatestNews';
import Downloads from '../pages/Downloads';
import Contact from '../pages/Contact';
import Faqs from '../pages/Faqs';
import BlogDetails from '../pages/BlogDetails';
import LatestNewsDetails from '../pages/LatestNewsDetails';
import Page from '../pages/Page';
import Apply from '../pages/Apply';

const appHistory = {
  basename: process.env.PUBLIC_URL
};
const history = createBrowserHistory(appHistory);
class AppRoute extends Component {

  constructor() {
    super();
    this.state = {
      user: {}
    }

  }

  componentDidMount() {

    axios.get(AppURL.UserData).then((response) => {
      this.setUser(response.data)

    }).catch(error => {

    });

  }

  setUser = (user) => {

    this.setState({ user: user })

  }


  render() {
    return (

      <Fragment>
        <Header />

        <Router basename={process.env.PUBLIC_URL} history={history}>

          <Route exact path='/' render={(props) => <Home {...props} key={Date.now()} />} />
          <Route exact path='/gallery/gallery' render={(props) => <Gallery {...props} key={Date.now()} />} />
          <Route exact path='/video/video' render={(props) => <Video {...props} key={Date.now()} />} />
          <Route exact path='/clients/clients' render={(props) => <Clients {...props} key={Date.now()} />} />
          <Route exact path='/blog/blog' render={(props) => <Blog {...props} key={Date.now()} />} />
          <Route exact path='/testimonials/testimonials' render={(props) => <Testimonials {...props} key={Date.now()} />} />
          <Route exact path='/news/news' render={(props) => <LatestNews {...props} key={Date.now()} />} />
          <Route exact path='/downloads/downloads' render={(props) => <Downloads {...props} key={Date.now()} />} />
          <Route exact path='/contact/contact-us' render={(props) => <Contact {...props} key={Date.now()} />} />
          <Route exact path='/faqs/frequently-asked-questions' render={(props) => <Faqs {...props} key={Date.now()} />} />
          <Route exact path='/blog/:blog_url' render={(props) => <BlogDetails {...props} key={Date.now()} />} />
          <Route exact path='/news/:latestnews_url' render={(props) => <LatestNewsDetails {...props} key={Date.now()} />} />
          <Route exact path='/:page_url' render={(props) => <Page {...props} key={Date.now()} />} />

          <Route exact path='/apply/apply-online-for-study-medicine-in-rostov-state-medical-university-russia' render={(props) => <Apply {...props} key={Date.now()} />} />

        </Router>
        <Footer />
      </Fragment>

    )
  }
}

export default AppRoute;
