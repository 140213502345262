import React, { Component, Fragment } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import DesktopAddressWithOutPhone from './address/DesktopAddressWithOutPhone';
import MobileAddressWithOutPhone from './address/MobileAddressWithOutPhone';
import BlinkingText from './BlinkingText';
import Logo from './Logo';


class LogoWithAddress extends Component {

    render() {
        return (
            <Fragment>
                <Container>
                    <Row>

                        <Col lg={3} md={3} sm={12} xs={12} className="text-center">
                            <Logo />
                        </Col>
                        <Col lg={3} md={3} sm={12} xs={12} className="text-center">
                            <BlinkingText />
                        </Col>
                        <Col lg={6} md={6} sm={12} xs={12}>
                            <div className='Desktop'>
                                <DesktopAddressWithOutPhone />
                            </div>
                            <div className='Mobile'>
                                <MobileAddressWithOutPhone />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        )
    }
}

export default LogoWithAddress
