import React, { Component, Fragment } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import AppURL from '../../../api/AppURL';
import axios from 'axios';

class DesktopAddressWithOutPhone extends Component {
    constructor() {
        super();
        this.state = {
            contact_number: "",
            contact_email: "",
            contact_address: ""

        }

    }
    componentDidMount() {

        axios.post(AppURL.GetPhoneLimit).then(response => {
            let StatusCode = response.status;
            if (StatusCode == 200) {
                let JsonData = (response.data['Phone'])[0];
                this.setState({
                    contact_number: JsonData['contact_number']
                });
            }

        }).catch(error => {

        });

        axios.post(AppURL.GetEmailLimit).then(response => {
            let StatusCode = response.status;
            if (StatusCode == 200) {
                let JsonData = (response.data['Email'])[0];
                this.setState({
                    contact_email: JsonData['contact_email']
                });
            }

        }).catch(error => {

        });


        axios.post(AppURL.GetContactUsLimit).then(response => {
            let StatusCode = response.status;
            if (StatusCode == 200) {
                let JsonData = (response.data['Contact'])[0];
                this.setState({
                    contact_address: JsonData['contact_address']
                });
            }

        }).catch(error => {

        });
    }
    render() {
        return (
            <Fragment>
                <Row>

                    <Col lg={6} md={6} sm={12} xs={12}>
                        <div className="widget">
                            <Row>
                                <Col lg={2} md={2} sm={12} xs={12}>
                                    <i className="fa fa-envelope"></i>
                                </Col>
                                <Col lg={10} md={10} sm={12} xs={12}>
                                    <a href={`mailto:${this.state.contact_email}`} className="font-12">Mail us for more details</a>
                                    <div className="font-16"> {this.state.contact_email}</div>
                                </Col>
                            </Row>
                        </div>

                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12}>
                        <div className="widget">
                            <Row>
                                <Col lg={2} md={2} sm={12} xs={12}>
                                    <i className="fa fa-map"></i>
                                </Col>
                                <Col lg={10} md={10} sm={12} xs={12}>
                                    <a href="#" className="font-12">Our Location</a>
                                    <div className="font-13"> <div dangerouslySetInnerHTML={{ __html: this.state.contact_address }} /></div>
                                </Col>
                            </Row>
                        </div>

                    </Col>
                </Row>
            </Fragment>


        )
    }
}

export default DesktopAddressWithOutPhone
