import axios from 'axios';
import React, { Component } from 'react'
import { Fragment } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import Logo from '../Logo';
import Socialmedia from '../Socialmedia';
import MobileDropdown from './MobileDropdown';
import AppURL from '../../../api/AppURL';

class MobileMenu extends Component {

    constructor() {
        super();
        this.state = {
            MenuData: []
        }


    }

    componentDidMount() {

        axios.post(AppURL.GetMenu).then(response => {
            this.setState({ MenuData: response.data['Menu'] });

        }).catch(error => {

        });
    }


    render() {
        return (
            <Fragment>
                <Container>
                    <nav className="menu-side-nav">
                        <Row>
                            <Col lg={12} md={12} xl={12} xxl={12} sm={12} xs={12}>
                                <div className="logo"> <Logo /></div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12} md={12} xl={12} xxl={12} sm={12} xs={12}>
                                <div className="menu-inner">
                                    <div className="collapse navbar-collapse show clearfix" id="navbarSupportedContent">

                                        <ul className="navigation">

                                            <li><a href={process.env.PUBLIC_URL + '/'}>Home</a></li>
                                            <MobileDropdown data={this.state.MenuData} />
                                            <li><a href={process.env.PUBLIC_URL + '/'}>Blog</a></li>
                                            <li><a href={process.env.PUBLIC_URL + '/contact/contact-us'}>Contact Us</a></li>

                                        </ul>



                                    </div>
                                </div>

                                <div class="social-links">
                                    <Socialmedia />
                                </div>

                            </Col>
                        </Row>
                    </nav>
                </Container>
            </Fragment>
        )
    }
}

export default MobileMenu
