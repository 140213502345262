import React, { Component } from 'react'
import { Fragment } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import AppURL from '../../api/AppURL';
import axios from 'axios';



class PageGallery extends Component {

    constructor() {
        super();
        this.state = {
            PageData: [],
            seo_title: "",
            seo_description: "",
            seo_keywords: ""


        }
    }

    componentDidMount() {

        let page_url = this.props.page_url;
        axios.post(AppURL.GetPageGallery(page_url)).then(response => {

            this.setState({ PageData: response.data['Page Gallery'] });

        }).catch(error => {

        });

        axios.post(AppURL.GetSeo(page_url)).then(response => {
            let StatusCode = response.status;
            if (StatusCode == 200) {
                let JsonData = (response.data['Seo'])[0];
                this.setState({
                    seo_title: JsonData['seo_title'],

                });
            }

        }).catch(error => {

        });

    }

    render() {
        let PageGalleryList = this.state.PageData;

        if (PageGalleryList.length > 0) {
            const PageGalleryView = PageGalleryList.map((PageGalleryList, i) => {

                return <Col lg={4} md={4} xl={4} xxl={4} sm={12} xs={12} key={i.toString()}>
                    <img src={PageGalleryList.page_image} alt={this.state.seo_title} className="img-fluid rounded" />
                </Col>
            })


            return (
                <Fragment>

                    {PageGalleryView}


                </Fragment>
            )
        }
        else {

        };


    }
}

export default PageGallery