import React, { Component, Fragment } from 'react'
import { Breadcrumb, Container, Row, Col } from 'react-bootstrap'
import AppURL from '../../api/AppURL';
import axios from 'axios';
import PageGallery from './PageGallery';
import PageVideo from './PageVideo';
import PageDownloads from './PageDownloads';
import PageFaqs from './PageFaqs';
import PageFeatures from './PageFeatures';
import PageServices from './PageServices';
import PageOffers from './PageOffers';
import SeoTitle from '../seo/SeoTitle';
import WebsiteRating from '../seo/WebsiteRating';
import PageContact from './PageContact';

class PageDetails extends Component {
    constructor() {
        super();
        this.state = {
            PageData: [],

        }
    }


    render() {
        let PageAllData = this.props.data;
        let page_name = PageAllData['pageDetails'][0]['page_name'];
        let page_text = PageAllData['pageDetails'][0]['page_text'];
        let page_image = PageAllData['pageDetails'][0]['page_image'];
        let page_url = PageAllData['pageDetails'][0]['page_url'];
        let seo_title = PageAllData['seo'][0]['seo_title'];

        return (
            <Fragment>
                <SeoTitle page_url={page_url} />
                <WebsiteRating page_url={page_url} />
                <Breadcrumb className="page-title">
                    <Container>
                        <Row>
                            <Col lg={12} md={12} xl={12} xxl={12} sm={12} xs={12}>
                                <div className="content-box">
                                    <div className="content-wrapper">
                                        <div className="title">
                                            <h1>{page_name}</h1>
                                        </div>
                                        <ul className="bread-crumb">
                                            <li><a href={process.env.PUBLIC_URL + '/'}>Home </a></li>
                                            <li className="active">{page_name}</li>
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Breadcrumb>
                <section className='inner-pages-details'>
                    <Container>
                        <Row>
                            <Col lg={12} md={12} xl={12} xxl={12} sm={12} xs={12}>
                                <img src={page_image} alt={seo_title} className='img-fluid' />
                                <h1>{page_name}</h1>
                                <div dangerouslySetInnerHTML={{ __html: page_text }} />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12} md={12} xl={12} xxl={12} sm={12} xs={12}>
                                <PageGallery page_url={page_url} />
                                <PageDownloads page_url={page_url} />
                                <PageFeatures page_url={page_url} />
                                <PageServices page_url={page_url} />
                                <PageVideo page_url={page_url} />
                                <PageFaqs page_url={page_url} />
                                <PageOffers page_url={page_url} />
                            </Col>
                        </Row>
                    </Container>

                </section>

                <PageContact page_url={page_url} />

            </Fragment>
        )
    }
}

export default PageDetails
