import React, { Component, Fragment } from 'react'
import AppURL from '../../api/AppURL';
import axios from 'axios';

class BlinkingText extends Component {
    constructor(props) {
        super();
        this.state = {
            BlinkingTextData: []


        }
    }
    componentDidMount() {

        axios.post(AppURL.GetBlinkingText).then(response => {

            this.setState({ BlinkingTextData: response.data['Blinking Text'] });

        }).catch(error => {

        });

    }
    render() {
        const BlinkingTextList = this.state.BlinkingTextData;
        const BlinkingTextView = BlinkingTextList.map((BlinkingTextList, i) => {
            return <div key={i.toString()} className="BlinkingText">
                <h5>{BlinkingTextList.blinking_name}</h5>
                <h6>{BlinkingTextList.blinking_title}</h6>

            </div>
        })
        return (
            <Fragment>
                {BlinkingTextView}
            </Fragment>

        )
    }
}

export default BlinkingText
