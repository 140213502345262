import React, { Component, Fragment } from "react";
import { Container, Row, Col } from "react-bootstrap";
import ContactPosition from "./ContactPosition";
import CopyRights from "./CopyRights";
import Logo from './Logo';
import ContactDetails from './ContactDetails';
import Socialmedia from './Socialmedia';
import FooterAddress from "./FooterAddress";
import Subscribe from "./Subscribe";

import AppURL from '../../api/AppURL';
import axios from 'axios';
import ScrollToTop from "./ScrollToTop";
import JavaScriptBottom from "./JavaScriptBottom";
import ApplyPosition from "./ApplyPosition";

class Footer extends Component {
    constructor() {
        super();
        this.state = {
            about_text: ""

        }

    }
    componentDidMount() {

        axios.post(AppURL.GetHomeAbout).then(response => {
            let StatusCode = response.status;
            if (StatusCode == 200) {
                let JsonData = (response.data['Home About'])[0];
                this.setState({
                    about_text: JsonData['about_text']

                });
            }

        }).catch(error => {

        });
    }
    render() {
        return (
            <Fragment>
                <div className="footer">
                    <Container>
                        <Row>
                            <Col lg={3} md={3} xl={3} xxl={3} sm={6} xs={12}>
                                <Row>
                                    <Col lg={12} md={12} xl={12} xxl={12} sm={12} xs={12}>
                                        <h4><Logo /></h4>
                                    </Col>
                                    <Col lg={12} md={12} xl={12} xxl={12} sm={12} xs={12}>
                                        <div className="text">

                                        </div>
                                    </Col>
                                    <Col lg={12} md={12} xl={12} xxl={12} sm={12} xs={12}>
                                        <div className="footer-social">
                                            <h5>Follow Us On:</h5>
                                            <Socialmedia />

                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg={3} md={3} xl={3} xxl={3} sm={6} xs={12}>
                                <h4>Quick Links</h4>
                                <ul>
                                    <li><a href={process.env.PUBLIC_URL + '/'}><span className="fa fa-angle-double-right"></span>Home</a></li>
                                    <li><a href={process.env.PUBLIC_URL + '/gallery/gallery'}><span className="fa fa-angle-double-right"></span>Photo Gallery</a></li>
                                    <li><a href={process.env.PUBLIC_URL + '/video/video'}><span className="fa fa-angle-double-right"></span>Video Gallery</a></li>
                                    <li><a href={process.env.PUBLIC_URL + '/clients/clients'}><span className="fa fa-angle-double-right"></span>Clients</a></li>
                                    <li><a href={process.env.PUBLIC_URL + '/testimonials/testimonials'}><span className="fa fa-angle-double-right"></span>Testimonials</a></li>
                                </ul>

                            </Col>
                            <Col lg={3} md={3} xl={3} xxl={3} sm={6} xs={12}>
                                <h4>Quick Links</h4>
                                <ul>
                                    <li><a href={process.env.PUBLIC_URL + '/blog/blog'}><span className="fa fa-angle-double-right"></span>Blog</a></li>
                                    <li><a href={process.env.PUBLIC_URL + '/news/news'}><span className="fa fa-angle-double-right"></span>News</a></li>
                                    <li><a href={process.env.PUBLIC_URL + '/contact/contact-us'}><span className="fa fa-angle-double-right"></span>Contact Us</a></li>
                                    <li><a href={process.env.PUBLIC_URL + '/faqs/frequently-asked-questions'}><span className="fa fa-angle-double-right"></span>Faqs</a></li>
                                    <li><a href={process.env.PUBLIC_URL + '/downloads/downloads'}><span className="fa fa-angle-double-right"></span>Downloads</a></li>
                                </ul>
                            </Col>

                            <Col lg={3} md={3} xl={3} xxl={3} sm={6} xs={12}>

                                <h4>Contact Us</h4>
                                <FooterAddress />


                            </Col>

                        </Row>

                    </Container >

                </div >

                <CopyRights />
                <ContactPosition />
                <ApplyPosition />
                <ScrollToTop />
                <JavaScriptBottom />
            </Fragment >


        )
    }
}

export default Footer;	