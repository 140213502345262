import React, { Component } from 'react'
import { Fragment } from 'react'


class DesktopDropdown extends Component {

    constructor(props) {
        super();
    }

    render() {

        const MenuList = this.props.data;
        const MenuView = MenuList.map((MenuList, i) => {

            return <li className="menu-hasdropdown" key={i.toString()}>
                <a href={process.env.PUBLIC_URL + "/" + MenuList.page_url}>{MenuList.menu_name}</a>
                <ul className="menu-dropdown">

                    {
                        (MenuList.category_name).map((CatList, i) => {

                            if (CatList.category_subcategory.length > 0) {
                                return <li className="menu-hasdropdown menu-hasflyout">
                                    <a href={process.env.PUBLIC_URL + "/" + CatList.page_url} >{CatList.category_name}</a>
                                    <ul className="menu-dropdown">
                                        {
                                            (CatList.category_subcategory).map((SubList, i) => {
                                                return <li>
                                                    <a href={process.env.PUBLIC_URL + "/" + SubList.page_url} >{SubList.subcategory_name}</a>

                                                </li>
                                            })
                                        }
                                    </ul>
                                </li>
                            }
                            else {
                                return <li><a href={process.env.PUBLIC_URL + "/" + CatList.page_url} >{CatList.category_name}</a></li>

                            }


                        })

                    }



                </ul>
            </li>

        });


        return (
            <Fragment>
                {MenuView}
            </Fragment>
        )
    }
}

export default DesktopDropdown
