import React, { Component } from 'react'
import { Fragment } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import AppURL from '../../api/AppURL';
import axios from 'axios';

class PageOffers extends Component {
    constructor() {
        super();
        this.state = {
            PageData: [],
            seo_title: "",
            seo_description: "",
            seo_keywords: ""
        }
    }

    componentDidMount() {

        let page_url = this.props.page_url;
        axios.post(AppURL.GetOffers(page_url)).then(response => {

            this.setState({ PageData: response.data['Offers'] });

        }).catch(error => {

        });

        axios.post(AppURL.GetSeo(page_url)).then(response => {
            let StatusCode = response.status;
            if (StatusCode == 200) {
                let JsonData = (response.data['Seo'])[0];
                this.setState({
                    seo_title: JsonData['seo_title'],

                });
            }

        }).catch(error => {

        });

    }
    render() {
        let OffersList = this.state.PageData;

        if (OffersList.length > 0) {
            const OffersView = OffersList.map((OffersList, i) => {

                return <Col lg={12} md={12} xl={12} xxl={12} sm={12} xs={12} className='text-center' key={i.toString()}>
                    <img src={OffersList.offers_image} alt={this.state.seo_title} className="img-fluid rounded" />
                </Col>
            })


            return (
                <Fragment>

                    <Row>

                        {OffersView}


                    </Row>




                </Fragment>
            )
        }
        else {

        };
    }
}

export default PageOffers
