import React, { Component, Fragment } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Container, Row, Col } from 'react-bootstrap'
import AppURL from '../../../api/AppURL';
import axios from 'axios';
import HomeGalleryLoading from '../../PlaceHolder/HomeGalleryLoading';

class MobileHomeGallery extends Component {
    constructor() {
        super();
        this.state = {
            HomeGalleryData: [],
            isLoading: "",
            mainDiv: "d-none",
            seo_title: "",
            seo_description: "",
            seo_keywords: "",
            page_name: ""
        }
    }
    componentDidMount() {
        axios.post(AppURL.GetHomeGallery).then(response => {

            this.setState({ HomeGalleryData: response.data['Gallery'], isLoading: "d-none", mainDiv: "" });

        }).catch(error => {

        });
        axios.post(AppURL.GetStaticSeo("Home")).then(response => {
            let StatusCode = response.status;
            if (StatusCode == 200) {
                let JsonData = (response.data['Seo'])[0];
                this.setState({
                    seo_title: JsonData['seo_title'],

                });
            }

        }).catch(error => {

        });


    }
    render() {

        const HomeGalleryList = this.state.HomeGalleryData;
        const HomeGalleryView = HomeGalleryList.map((HomeGalleryList, i) => {
            return <Row className="align-items-center" key={i.toString()}>

                <Col lg={12} md={12} xl={12} xxl={12} sm={12} xs={12} className='text-center'>
                    <div className='HomeGallery-box'>
                        <div className='HomeGallery-box-1'>
                            <div className="HomeGallery-inner-box">
                                <img src={HomeGalleryList.gallery_image} alt={this.state.seo_title} className="img-fluid rounded" />
                            </div>
                            <div className='HomeGallery-view-overlay'>
                                <div className="HomeGallery-media-link">

                                    <a href={HomeGalleryList.gallery_image} ><i className="fa fa-search" /></a>
                                    <a href={process.env.PUBLIC_URL + '/'} className="media-link"><i className="fa fa-link" /></a>
                                </div>
                            </div>

                            <div class="HomeGallery-content">
                                <div class="HomeGallery-title">
                                    <h5>{HomeGalleryList.gallery_name}</h5>
                                </div>
                            </div>

                        </div>
                    </div>
                </Col>


            </Row>

        })

        var settings = {
            dots: false,
            infinite: false,
            speed: 500,
            autoplay: true,
            autoplaySpeed: 3000,
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0,
            arrows: false,
            responsive: [

                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: false
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 380,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };



        return (

            <Fragment>
                <Container>
                    <HomeGalleryLoading isLoading={this.state.isLoading} />
                    <Row className={this.state.mainDiv}>
                        <Slider {...settings}>

                            {HomeGalleryView}
                        </Slider>
                    </Row>
                </Container>
            </Fragment>

        )
    }
}

export default MobileHomeGallery
