import React, { Component, Fragment } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Container, Row, Col } from 'react-bootstrap'
import AppURL from '../../api/AppURL';
import axios from 'axios';
import HomeClientsLoading from '../../components/PlaceHolder/HomeClientsLoading';

class MobilePageClients extends Component {
    constructor() {
        super();
        this.state = {
            HomeClientsData: [],
            isLoading: "",
            mainDiv: "d-none",
            seo_title: "",
            seo_description: "",
            seo_keywords: "",
            page_name: ""
        }
    }
    componentDidMount() {
        axios.post(AppURL.GetHomeClients).then(response => {

            this.setState({ HomeClientsData: response.data['Clients'], isLoading: "d-none", mainDiv: "" });

        }).catch(error => {

        });

        axios.post(AppURL.GetStaticSeo("Clients")).then(response => {
            let StatusCode = response.status;
            if (StatusCode == 200) {
                let JsonData = (response.data['Seo'])[0];
                this.setState({
                    seo_title: JsonData['seo_title'],
                    seo_description: JsonData['seo_description'],
                    seo_keywords: JsonData['seo_keywords'],
                    page_name: JsonData['page_name'],

                });
            }

        }).catch(error => {

        });


    }
    render() {

        const HomeClientsList = this.state.HomeClientsData;
        const HomeClientsView = HomeClientsList.map((HomeClientsList, i) => {
            return <Row className="align-items-center" key={i.toString()}>

                <Col lg={12} md={12} xl={12} xxl={12} sm={12} xs={12} className='text-center'>
                    <div className='HomeClients-box'>
                        <div className='HomeClients-box-1'>
                            <div className="HomeClients-inner-box">
                                <img src={HomeClientsList.clients_image} alt={this.state.seo_title} className="img-fluid rounded" />
                            </div>
                            <div className='HomeClients-view-overlay'>
                                <div className="HomeClients-media-link">

                                    <a href={HomeClientsList.clients_name} ><i className="fa fa-search" /></a>
                                    <a href={process.env.PUBLIC_URL + '/'} className="media-link"><i className="fa fa-link" /></a>
                                </div>
                            </div>

                            <div class="HomeClients-content">
                                <div class="HomeClients-title">
                                    <h5>{HomeClientsList.clients_name}</h5>
                                </div>
                            </div>

                        </div>
                    </div>
                </Col>


            </Row>

        })

        var settings = {
            dots: false,
            infinite: false,
            speed: 500,
            autoplay: true,
            autoplaySpeed: 3000,
            slidesToShow: 6,
            slidesToScroll: 1,
            initialSlide: 0,
            arrows: false,
            responsive: [

                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: false
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 380,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };



        return (

            <Fragment>
                <Container>
                    <HomeClientsLoading isLoading={this.state.isLoading} />
                    <Row className={this.state.mainDiv}>
                        <Slider {...settings}>

                            {HomeClientsView}
                        </Slider>
                    </Row>
                </Container>
            </Fragment>

        )
    }
}

export default MobilePageClients
