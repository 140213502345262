import React, { Component, Fragment } from 'react'
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Container, Row, Col } from 'react-bootstrap'
import AppURL from '../../../api/AppURL';
import axios from 'axios';
import HomeServicesLoading from '../../PlaceHolder/HomeServicesLoading';

class MobileHomeServices extends Component {
    constructor() {
        super();
        this.state = {
            HomeServicesData: [],
            isLoading: "",
            mainDiv: "d-none",
            seo_title: "",
            seo_description: "",
            seo_keywords: "",
            page_name: ""
        }
    }
    componentDidMount() {
        axios.post(AppURL.GetHomeServices).then(response => {

            this.setState({ HomeServicesData: response.data['Home Services'], isLoading: "d-none", mainDiv: "" });

        }).catch(error => {

        });
        axios.post(AppURL.GetStaticSeo("Home")).then(response => {
            let StatusCode = response.status;
            if (StatusCode == 200) {
                let JsonData = (response.data['Seo'])[0];
                this.setState({
                    seo_title: JsonData['seo_title'],

                });
            }

        }).catch(error => {

        });


    }
    render() {

        const HomeServicesList = this.state.HomeServicesData;
        const HomeServicesView = HomeServicesList.map((HomeServicesList, i) => {
            return <Row className="align-items-center" key={i.toString()}>

                <Col lg={12} md={12} xl={12} xxl={12} sm={12} xs={12} className='text-center'>
                    <div className='HomeServices-box-1'>
                        <span className="HomeServices-inner-box">
                            <img src={HomeServicesList.services_image} alt={this.state.seo_title} className="img-fluid rounded" />
                        </span>
                        <div>
                            <h5>{HomeServicesList.services_name}</h5>
                            <div dangerouslySetInnerHTML={{ __html: HomeServicesList.services_text }} />
                        </div>
                        <Link to={HomeServicesList.page_name} className="btn_one">{HomeServicesList.button_name}</Link>

                    </div>
                </Col>


            </Row>

        })

        var settings = {
            dots: false,
            infinite: false,
            speed: 500,
            autoplay: true,
            autoplaySpeed: 3000,
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0,
            arrows: false,
            responsive: [

                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: false
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 380,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };



        return (

            <Fragment>
                <Container>
                    <HomeServicesLoading isLoading={this.state.isLoading} />
                    <Row className={this.state.mainDiv}>
                        <Slider {...settings}>

                            {HomeServicesView}
                        </Slider>
                    </Row>
                </Container>
            </Fragment>

        )
    }
}

export default MobileHomeServices
