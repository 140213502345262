import React, { Component, Fragment } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import heading from '../../../assets/images/heading-line.png';
import DesktopHomeVideo from '../desktop/DesktopHomeVideo';
import MobileHomeVideo from '../mobile/MobileHomeVideo';
import AppURL from '../../../api/AppURL';
import axios from 'axios';
import HeadingsLoading from '../../PlaceHolder/HeadingsLoading';

class HomeVideo extends Component {

    constructor() {
        super();
        this.state = {
            HeadingsData: [],
            isLoading: "",
            mainDiv: "d-none",
            seo_title: "",
            seo_description: "",
            seo_keywords: "",
            page_name: ""
        }
    }
    componentDidMount() {
        axios.post(AppURL.GetHeadings("Video Gallery")).then(response => {

            this.setState({ HeadingsData: response.data['Headings'], isLoading: "d-none", mainDiv: "" });

        }).catch(error => {

        });

        axios.post(AppURL.GetStaticSeo("Home")).then(response => {
            let StatusCode = response.status;
            if (StatusCode == 200) {
                let JsonData = (response.data['Seo'])[0];
                this.setState({
                    seo_title: JsonData['seo_title'],

                });
            }

        }).catch(error => {

        });


    }

    render() {

        const HeadingsList = this.state.HeadingsData;
        if (HeadingsList.length > 0) {
            const HeadingsView = HeadingsList.map((HeadingsList, i) => {
                return <div key={i.toString()}>
                    <Col lg={12} md={12} xl={12} xxl={12} sm={12} xs={12} className="text-center">
                        <h1>{HeadingsList.headings_name}</h1>
                        <img src={heading} alt={this.state.seo_title} className="img-fluid" />
                        <div dangerouslySetInnerHTML={{ __html: HeadingsList.headings_text }} />
                    </Col>
                </div >

            })


            return (
                <Fragment>
                    <section className='HomeVideo'>
                        <Container>
                            <HeadingsLoading isLoading={this.state.isLoading} />
                            <div className={this.state.mainDiv}>
                                {HeadingsView}
                            </div>

                            <div className='Desktop'>
                                <DesktopHomeVideo />
                            </div>
                            <div className='Mobile'>
                                <MobileHomeVideo />
                            </div>
                        </Container>
                    </section>

                </Fragment>
            )
        }
        else {

        };

    }
}

export default HomeVideo

