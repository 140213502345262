import React, { Component } from 'react'
import { Fragment } from 'react'
import { Breadcrumb, Container, Row, Col } from 'react-bootstrap'

class ApplyNow extends Component {
    render() {
        return (
            <Fragment>
                <form id="ttm-quote-form" className="row ttm-quote-form clearfix" name="f1" method="post" action="https://app.todesign.in/react/rostgmu.net/apply.php">
                    <div className="col-sm-6 col-md-6">
                        <div className="form-group">
                            <label>First Name</label>
                            <input name="fname" id="fname" type="text" className="form-control bg-white" placeholder="First Name*" required="required" />
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6">
                        <div className="form-group">
                            <label>Last Name</label>
                            <input name="lname" id="lname" type="text" placeholder="Last Name" required="required" className="form-control bg-white" />
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6">
                        <div className="form-group">
                            <label>Phone Number</label>
                            <input name="phone" id="phone" type="text" placeholder="Phone Number*" required="required" className="form-control bg-white" />
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6">
                        <div className="form-group">
                            <label>Email Address</label>
                            <input name="email" id="email" type="email" placeholder="Email Address*" required="required" className="form-control bg-white" />
                        </div>
                    </div>

                    <div className="col-sm-6 col-md-6">
                        <div className="form-group">
                            <label>Date of Birth</label>
                            <input name="dob" id="dob" type="date" placeholder="Date of Birth*" required="required" className="form-control bg-white" />
                        </div>
                    </div>

                    <div className="col-sm-6 col-md-6">
                        <div className="form-group">
                            <label>Nationality</label>
                            <input name="nationality" id="nationality" type="text" placeholder="Nationality*" required="required" className="form-control bg-white" />
                        </div>
                    </div>

                    <div className="col-sm-4 col-md-4">
                        <div className="form-group">
                            <label>Passport Number</label>
                            <input name="passport" id="passport" type="text" placeholder="Passport Number*" required="required" className="form-control bg-white" />
                        </div>
                    </div>

                    <div className="col-sm-4 col-md-4">
                        <div className="form-group">
                            <label>Passport Issue on</label>
                            <input name="pissue" id="pissue" type="text" placeholder="Passport Issue on*" required="required" className="form-control bg-white" />
                        </div>
                    </div>

                    <div className="col-sm-4 col-md-4">
                        <div className="form-group">
                            <label>Passport Valid To</label>
                            <input name="pvalid" id="pvalid" type="text" placeholder="Passport Valid To*" required="required" className="form-control bg-white" />
                        </div>
                    </div>

                    <div className="col-sm-6 col-md-6">
                        <div className="form-group">
                            <label>RSMU Faculties </label>
                            <select id="faculties" name="faculties" placeholder="RSMU Faculties*" type="text" required="required" className="form-control bg-white"
                            >
                                <option value="disabled selected" className="form-group">Select the one</option>
                                <option value="Medicine (MBBS)" >Medicine (MBBS)</option>
                                <option value="Dentistry (BDS)" >Dentistry (BDS)</option>
                                <option value="Pharmacy" >Pharmacy</option>
                                <option value="Pediatrics" >Pediatrics</option>
                                <option value="Preparatory Course" >Preparatory Course</option>
                                <option value="Post Graduation" >Post Graduation</option>
                            </select>
                        </div>
                    </div>

                    <div className="col-sm-6 col-md-6">
                        <div className="form-group">
                            <label>Medium of Instruction</label>
                            <select id="medium" name="medium" placeholder="Medium of Instruction*" type="text" required="required" className="form-control bg-white"   >
                                <option value="disabled selected" className="form-group">Select the one</option>
                                <option value="English Medium" >English Medium</option>
                                <option value="Russian Medium" >Russian Medium</option>
                            </select>
                        </div>
                    </div>




                    <div className="col-sm-6 col-md-6">
                        <div className="form-group">
                            <label>Address</label>
                            <textarea name="address" id="address" rows={5} placeholder="Address..." required="required" className="form-control bg-white" />
                        </div>
                    </div>

                    <div className="col-sm-6 col-md-6">
                        <div className="form-group">
                            <label>Education Background</label>
                            <textarea name="education" id="education" rows={5} placeholder="Education Background..." required="required" className="form-control bg-white" />
                        </div>
                    </div>


                    <div className="col-md-12">
                        <div className="text-left">
                            <button type="submit" id="submit" name="submit" value="Submit" className="mt-3 ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor" >
                                Submit
                            </button>
                        </div>
                    </div>
                </form>
            </Fragment>
        )
    }
}

export default ApplyNow
